/* eslint-disable react/jsx-props-no-spreading */
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CustomerForm from '../../components/customers/Form';
import Layout from '../../components/layout/Layout';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function EditCustomer() {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Layout title="Clientes">
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <IconButton
            onClick={() => history.goBack()}
            style={{ marginTop: -5 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            component="span"
            style={{ fontWeight: 'bold', marginLeft: 5 }}
          >
            {id ? 'Editar Cliente' : 'Adicionar Novo Cliente'}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="cliente tabs"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Dados do Cliente" {...a11yProps(0)} />
            </Tabs>
            <br />
            <TabPanel value={value} index={0}>
              <CustomerForm id={id} />
            </TabPanel>
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default EditCustomer;
