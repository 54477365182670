import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getCustomerById } from '../../api/customers/getCustomerById';
import Alert from '../Alert/Alert';
import Loading from '../Loading/Loading';
import CustomerDataPdfPreview from './CustomerDataPdfPreview';

function CustomerData({ id }) {
  const history = useHistory();
  const [data, setData] = useState();
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          const res = await getCustomerById({ id });

          setData(res);
          setLoadingData(false);
        } catch (error) {
          setLoadingData(false);
          Alert.fire(error.message, '', 'error');
        }
      })();
    } else {
      setLoadingData(false);
    }
  }, [id]);

  if (loadingData) {
    return <Loading />;
  }

  return (
    <Grid container alignItems="center">
      <Grid item xs={12}>
        <IconButton onClick={() => history.goBack()} style={{ marginTop: -5 }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography
          component="span"
          style={{ fontWeight: 'bold', marginLeft: 5 }}
        >
          Ficha do Cliente
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CustomerDataPdfPreview data={data} />
      </Grid>
    </Grid>
  );
}

CustomerData.defaultProps = {
  id: null
};

CustomerData.propTypes = {
  id: PropTypes.string
};

export default CustomerData;
