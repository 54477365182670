import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';
import { useHistory } from 'react-router-dom';
import CustomerForm from '../../components/customers/Form';
import Layout from '../../components/layout/Layout';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  }
}));

function CreateCustomer() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Layout title="Clientes">
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <IconButton
            onClick={() => history.goBack()}
            style={{ marginTop: -5 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            component="span"
            style={{ fontWeight: 'bold', marginLeft: 5 }}
          >
            Adicionar Novo Cliente
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <CustomerForm />
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default CreateCustomer;
