import { MenuItem, useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { getCategories } from '../api/categories/getCategories';
import { getCustomers } from '../api/customers/getCustomers';
import Alert from '../components/Alert/Alert';
import Loading from '../components/Loading/Loading';
import CnpjInput from '../components/MaskedInput/CnpjInput';
import CpfInput from '../components/MaskedInput/CpfInput';
import MobilePhoneInput from '../components/MaskedInput/MobilePhoneInput';
import CustomersTable from '../components/customers/CustomersTable';
import Layout from '../components/layout/Layout';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2)
    },
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  headerButtons: {
    [theme.breakpoints.down('md')]: {
      marginBottom: 15,
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      marginBottom: 0,
      marginRight: 15
    }
  }
}));

const perPage = 12;

function Customers() {
  const theme = useTheme();
  const mediaQueryMatchesLargeScreens = useMediaQuery(
    theme.breakpoints.up('lg')
  );
  const { control, handleSubmit, errors } = useForm();
  const classes = useStyles();
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState();
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const res = await getCustomers({ page, perPage, ...filter });
        setLoading(false);
        setRows(res.data);
        setTotalPages(Math.ceil(res.total / perPage));
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [page, filter]);

  useEffect(() => {
    (async () => {
      try {
        const res = await getCategories({ page: 1, limit: 9999 });
        setCategories(res.data);
      } catch (error) {
        //
      }
    })();
  }, []);

  const handleChangePage = (_, value) => {
    setPage(value);
  };

  const handleClickFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleDelete = async () => {
    try {
      if (rows.length === 1 && page > 1) {
        setPage(page - 1);
      } else {
        const res = await getCustomers({ page });
        setRows(res.data);
        setTotalPages(Math.ceil(res.total / perPage));
      }
    } catch (error) {
      //
    }
  };

  const onSubmit = async (formData) => {
    try {
      setLoading(true);

      setPage(1);
      setFilter(formData);
    } catch (error) {
      setLoading(false);
      Alert.fire('', error.message, 'error');
    }
  };

  return (
    <Layout title="Clientes">
      <Grid container spacing={3}>
        <Grid item lg={12}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => history.push('/clientes/criar')}
            startIcon={<AddIcon />}
            className={classes.headerButtons}
          >
            Adicionar Cliente
          </Button>
          {!mediaQueryMatchesLargeScreens ? (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<FilterListIcon />}
              className={classes.headerButtons}
              onClick={handleClickFilter}
              style={{ marginBottom: 0 }}
            >
              Filtro
            </Button>
          ) : null}
        </Grid>
        {showFilter || mediaQueryMatchesLargeScreens ? (
          <Grid item lg={12}>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={3}>
                  <Controller
                    control={control}
                    render={({ onChange, onBlur, value }) => (
                      <TextField
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        error={!!errors.name?.message}
                        helperText={errors.name?.message}
                        margin="dense"
                        fullWidth
                        id="name"
                        label="Buscar por Nome"
                        name="name"
                      />
                    )}
                    name="name"
                    defaultValue=""
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={2}>
                  <Controller
                    control={control}
                    render={({ onChange, onBlur, value }) => (
                      <TextField
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        error={!!errors.mobile_phone?.message}
                        helperText={errors.mobile_phone?.message}
                        margin="dense"
                        fullWidth
                        id="mobile_phone"
                        label="Celular"
                        name="mobile_phone"
                        InputProps={{
                          inputComponent: MobilePhoneInput
                        }}
                      />
                    )}
                    name="mobile_phone"
                    defaultValue=""
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={2}>
                  <Controller
                    control={control}
                    render={({ onChange, onBlur, value }) => (
                      <TextField
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        error={!!errors.cpf?.message}
                        helperText={errors.cpf?.message}
                        margin="dense"
                        fullWidth
                        id="cpf"
                        label="CPF"
                        name="cpf"
                        InputProps={{
                          inputComponent: CpfInput
                        }}
                      />
                    )}
                    name="cpf"
                    defaultValue=""
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={2}>
                  <Controller
                    control={control}
                    render={({ onChange, onBlur, value }) => (
                      <TextField
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        error={!!errors.cnpj?.message}
                        helperText={errors.cnpj?.message}
                        margin="dense"
                        fullWidth
                        id="cnpj"
                        label="CNPJ"
                        name="cnpj"
                        InputProps={{
                          inputComponent: CnpjInput
                        }}
                      />
                    )}
                    name="cnpj"
                    defaultValue=""
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={2}>
                  <Controller
                    control={control}
                    render={({ onChange, onBlur, value }) => (
                      <TextField
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        margin="dense"
                        fullWidth
                        id="category_id"
                        label="Categoria"
                        name="category_id"
                        select
                      >
                        <MenuItem value={null}>Todas</MenuItem>
                        {categories.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.description}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    name="category_id"
                    defaultValue=""
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={1}>
                  <Button
                    type="submit"
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{ marginTop: 23 }}
                  >
                    <SearchIcon />
                  </Button>
                  {!mediaQueryMatchesLargeScreens ? (
                    <Button
                      type="button"
                      variant="text"
                      size="small"
                      color="primary"
                      style={{ marginTop: 23, marginLeft: 20 }}
                      onClick={() => setShowFilter(false)}
                    >
                      Fechar Filtro
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
            </form>
          </Grid>
        ) : null}
        <Grid
          item
          md={12}
          style={{ width: !mediaQueryMatchesLargeScreens ? '100%' : undefined }}
        >
          <Paper className={classes.paper}>
            {loading && <Loading />}
            {!loading && rows.length === 0 && page === 1 && (
              <Typography>Nenhum cliente encontrado</Typography>
            )}
            {!loading && rows.length > 0 && (
              <CustomersTable
                rows={rows}
                onDelete={handleDelete}
                page={page}
                totalPages={totalPages}
                onChangePage={handleChangePage}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default Customers;
