import axios from './index';

export async function login({ identifier, password }) {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_BACKEND}/auth/login`,
      { identifier, password },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    return res.data;
  } catch (error) {
    if (error?.response?.data?.error) {
      throw new Error(error?.response?.data?.error);
    }
    throw error;
  }
}
