import { useMediaQuery } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SettingsIcon from '@material-ui/icons/Settings';
import Pagination from '@material-ui/lab/Pagination';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { userIsMaster } from '../../api/userIsMaster';
import { deleteUser } from '../../api/users/deleteUser';
import Alert from '../Alert/Alert';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3)
  }
}));

function UsersTable({ rows, onDelete, totalPages, page, onChangePage }) {
  const theme = useTheme();
  const mediaQueryMatchesLargeScreens = useMediaQuery(
    theme.breakpoints.up('lg')
  );
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [userSelected, setUserSelected] = useState(null);

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setUserSelected(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setUserSelected(null);
  };

  const handleEdit = () => {
    history.push(`/usuarios/editar/${userSelected.id}`);
  };

  const handleDelete = async () => {
    setAnchorEl(null);
    if (userSelected.master) {
      return;
    }
    Alert.fire({
      title: `Deseja realmente excluir o usuário ${userSelected.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then(async (result) => {
      if (result.value) {
        try {
          await deleteUser({ id: userSelected?.id });
          if (onDelete) {
            onDelete();
          }
        } catch (error) {
          Alert.fire(error.message);
        }
      } else if (result.dismiss === Alert.DismissReason.cancel) {
        setUserSelected(null);
      }
    });
  };

  const renderTableActions = (row, align = 'right') => {
    return (
      <TableCell align={align}>
        {userIsMaster() && (
          <IconButton
            aria-controls="settings-menu"
            aria-haspopup="true"
            onClick={(event) => handleClick(event, row)}
            size="small"
          >
            <SettingsIcon />
          </IconButton>
        )}
      </TableCell>
    );
  };

  return (
    <>
      <Table
        size="small"
        style={{ minWidth: !mediaQueryMatchesLargeScreens ? 800 : undefined }}
      >
        <TableHead>
          <TableRow>
            {!mediaQueryMatchesLargeScreens ? (
              <TableCell align="left" width={40}>
                Ações
              </TableCell>
            ) : null}
            <TableCell>Nome</TableCell>
            <TableCell>E-mail</TableCell>
            <TableCell>Master</TableCell>
            {mediaQueryMatchesLargeScreens ? (
              <TableCell align="right">Ações</TableCell>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              {!mediaQueryMatchesLargeScreens
                ? renderTableActions(row, 'left')
                : null}
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell>{row.master ? 'Sim' : 'Não'}</TableCell>
              {mediaQueryMatchesLargeScreens ? renderTableActions(row) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Menu
        id="settings-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEdit}>
          <EditIcon fontSize="small" />
          <span style={{ marginLeft: 8 }}>Alterar</span>
        </MenuItem>
        {!userSelected?.master ? (
          <MenuItem onClick={handleDelete} style={{ color: 'red' }}>
            <DeleteIcon fontSize="small" color="inherit" />
            <span style={{ marginLeft: 8 }}>Excluir</span>
          </MenuItem>
        ) : null}
      </Menu>
      <div className={classes.seeMore}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={onChangePage}
          variant="outlined"
          color="primary"
          size="small"
        />
      </div>
    </>
  );
}

UsersTable.defaultProps = {
  onDelete: null,
  totalPages: undefined
};

UsersTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      username: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  onDelete: PropTypes.func,
  totalPages: PropTypes.number,
  page: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired
};

export default UsersTable;
