/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';

function PhoneInput(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={(rawValue) => {
        const numbers = rawValue.match(/\d/g);
        let numberLength = 0;
        if (numbers) {
          numberLength = numbers.join('').length;
        }

        if (numberLength > 10) {
          return [
            '(',
            /[1-9]/,
            /[1-9]/,
            ')',
            ' ',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/
          ];
        }
        return [
          '(',
          /[1-9]/,
          /[1-9]/,
          ')',
          ' ',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          '-',
          /\d/,
          /\d/,
          /\d/,
          /\d/
        ];
      }}
      placeholderChar={'\u2000'}
      showMask={false}
    />
  );
}

PhoneInput.propTypes = {
  inputRef: PropTypes.func.isRequired
};

export default PhoneInput;
