import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

function Loading() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress size={24} />
    </div>
  );
}

export default Loading;
