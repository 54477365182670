import { useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getUsers } from '../api/users/getUsers';
import Loading from '../components/Loading/Loading';
import Layout from '../components/layout/Layout';
import UsersTable from '../components/users/UsersTable';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2)
    },
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  }
}));

const perPage = 12;

function Users() {
  const theme = useTheme();
  const mediaQueryMatchesLargeScreens = useMediaQuery(
    theme.breakpoints.up('lg')
  );
  const classes = useStyles();
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const res = await getUsers({ page, perPage });
        setLoading(false);
        setRows(res.data);
        setTotalPages(Math.ceil(res.total / perPage));
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [page]);

  const handleChangePage = (_, value) => {
    setPage(value);
  };

  const handleDelete = async () => {
    try {
      if (rows.length === 1 && page > 1) {
        setPage(page - 1);
      } else {
        const res = await getUsers({ page, perPage });
        setRows(res.data);
        setTotalPages(Math.ceil(res.total / perPage));
      }
    } catch (error) {
      //
    }
  };

  return (
    <Layout title="Usuários do sistema">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => history.push('/usuarios/criar')}
            startIcon={<AddIcon />}
          >
            Adicionar Usuário
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ width: !mediaQueryMatchesLargeScreens ? '100%' : undefined }}
        >
          <Paper className={classes.paper}>
            {loading && <Loading />}
            {!loading && rows.length === 0 && page === 1 && (
              <Typography>Nenhum usuário encontrado</Typography>
            )}
            {!loading && rows.length > 0 && (
              <UsersTable
                rows={rows}
                onDelete={handleDelete}
                page={page}
                totalPages={totalPages}
                onChangePage={handleChangePage}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default Users;
