import { getToken } from '../getToken';
import axios from '../index';

export async function updateCategory({ id, description }) {
  try {
    await axios.put(
      `${process.env.REACT_APP_API_BACKEND}/categories/${id}`,
      { description },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': getToken()
        }
      }
    );
  } catch (error) {
    if (error?.response?.data?.error) {
      throw new Error(error?.response?.data?.error);
    }
    throw error;
  }
}
