import Button from '@material-ui/core/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import { isMobile } from 'react-device-detect';
import CustomerDataPdfDocument from './CustomerDataPdfDocument';

function ButtonDownload() {
  return (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      startIcon={<CloudDownloadIcon />}
      fullWidth
    >
      Baixar PDF
    </Button>
  );
}

function CustomerDataPdfPreview({ data }) {
  if (!data) {
    return null;
  }

  if (isMobile) {
    return (
      <PDFDownloadLink
        document={<CustomerDataPdfDocument data={data} />}
        fileName={`ficha-cliente-${data?.customer_internal_id}.pdf`}
      >
        {({ loading }) => (loading ? 'Loading...' : <ButtonDownload />)}
      </PDFDownloadLink>
    );
  }

  return (
    <PDFViewer
      style={{ width: '100%', height: 'calc(100vh - 210px)', border: 0 }}
    >
      <CustomerDataPdfDocument data={data} />
    </PDFViewer>
  );
}

CustomerDataPdfPreview.defaultProps = {
  data: null
};

CustomerDataPdfPreview.propTypes = {
  data: PropTypes.shape()
};

export default CustomerDataPdfPreview;
