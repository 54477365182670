import { useMediaQuery } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';
import SettingsIcon from '@material-ui/icons/Settings';
import Pagination from '@material-ui/lab/Pagination';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { deleteCustomer } from '../../api/customers/deleteCustomer';
import Alert from '../Alert/Alert';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3)
  }
}));

function CustomersTable({ rows, onDelete, totalPages, page, onChangePage }) {
  const theme = useTheme();
  const mediaQueryMatchesLargeScreens = useMediaQuery(
    theme.breakpoints.up('lg')
  );
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [customerSelected, setCustomerSelected] = useState(null);

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setCustomerSelected(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCustomerSelected(null);
  };

  const handleEdit = () => {
    history.push(`/clientes/editar/${customerSelected.id}`);
  };

  const handlePrint = () => {
    history.push(`/clientes/ficha/${customerSelected.id}`);
  };

  const handleDelete = async () => {
    setAnchorEl(null);
    Alert.fire({
      title: `Deseja realmente excluir o cliente ${customerSelected.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then(async (result) => {
      if (result.value) {
        try {
          await deleteCustomer({ id: customerSelected?.id });
          if (onDelete) {
            onDelete();
          }
        } catch (error) {
          Alert.fire(error.message);
        }
      } else if (result.dismiss === Alert.DismissReason.cancel) {
        setCustomerSelected(null);
      }
    });
  };

  const renderTableActions = (row, align = 'right') => {
    return (
      <TableCell align={align} style={{ padding: 0 }}>
        <IconButton
          aria-controls="settings-menu"
          aria-haspopup="true"
          onClick={(event) => handleClick(event, row)}
          size="small"
        >
          <SettingsIcon fontSize="small" />
        </IconButton>
      </TableCell>
    );
  };

  return (
    <>
      <Table
        size="small"
        style={{ minWidth: !mediaQueryMatchesLargeScreens ? 1000 : undefined }}
      >
        <TableHead>
          <TableRow>
            {!mediaQueryMatchesLargeScreens ? (
              <TableCell align="left" width={20} style={{ padding: 0 }} />
            ) : null}
            <TableCell width={30} style={{ paddingRight: 0 }}>
              ID
            </TableCell>
            <TableCell>Nome</TableCell>
            <TableCell width={160}>CPF/CNPJ</TableCell>
            <TableCell width={160}>Celular</TableCell>
            <TableCell width={160}>Categoria</TableCell>
            {mediaQueryMatchesLargeScreens ? (
              <TableCell align="right" width={50} style={{ paddingRight: 0 }}>
                Ações
              </TableCell>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              {!mediaQueryMatchesLargeScreens
                ? renderTableActions(row, 'left')
                : null}
              <TableCell style={{ paddingRight: 0 }}>
                {row.customer_internal_id}
              </TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>
                {row.cpf && row.cnpj
                  ? `${row.cpf} / ${row.cnpj}`
                  : `${row.cpf || ''}${row.cnpj || ''}`}
              </TableCell>
              <TableCell>{row.mobile_phone}</TableCell>
              <TableCell>{row.category?.description}</TableCell>
              {mediaQueryMatchesLargeScreens ? renderTableActions(row) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Menu
        id="settings-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEdit}>
          <EditIcon fontSize="small" />
          <span style={{ marginLeft: 8 }}>Alterar</span>
        </MenuItem>

        <MenuItem onClick={handlePrint}>
          <PrintIcon fontSize="small" />
          <span style={{ marginLeft: 8 }}>Imprimir</span>
        </MenuItem>

        <MenuItem onClick={handleDelete} style={{ color: 'red' }}>
          <DeleteIcon fontSize="small" color="inherit" />
          <span style={{ marginLeft: 8 }}>Excluir</span>
        </MenuItem>
      </Menu>
      <div className={classes.seeMore}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={onChangePage}
          variant="outlined"
          color="primary"
          size="small"
        />
      </div>
    </>
  );
}

CustomersTable.defaultProps = {
  onDelete: null,
  totalPages: undefined
};

CustomersTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      mobile_phone: PropTypes.string,
      cpf: PropTypes.string,
      cnpj: PropTypes.string
    })
  ).isRequired,
  onDelete: PropTypes.func,
  totalPages: PropTypes.number,
  page: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired
};

export default CustomersTable;
