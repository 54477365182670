import React from 'react';
import {
  Redirect,
  Route,
  HashRouter as Router,
  Switch
} from 'react-router-dom';
import { useAuth } from './contexts/auth';
import Categories from './pages/Categories';
import CreateCategory from './pages/Categories/CreateCategory';
import EditCategory from './pages/Categories/EditCategory';
import Customers from './pages/Customers';
import CreateCustomer from './pages/Customers/CreateCustomer';
import CustomerDataPage from './pages/Customers/CustomerDataPage';
import EditCustomer from './pages/Customers/EditCustomer';
import ImportExportCustomersPage from './pages/ImportExportCustomersPage';
import Login from './pages/Login';
import Users from './pages/Users';
import CreateUser from './pages/Users/CreateUser';
import EditUser from './pages/Users/EditUser';

export default function Routes() {
  const { signed, loading } = useAuth();

  if (loading) {
    return <p>Carregando...</p>;
  }

  if (signed) {
    return (
      <Router>
        <Switch>
          <Route exact path="/clientes">
            <Customers />
          </Route>
          <Route exact path="/clientes/criar">
            <CreateCustomer />
          </Route>
          <Route path="/clientes/editar/:id">
            <EditCustomer />
          </Route>
          <Route path="/clientes/ficha/:id">
            <CustomerDataPage />
          </Route>
          <Route exact path="/usuarios">
            <Users />
          </Route>
          <Route exact path="/usuarios/criar">
            <CreateUser />
          </Route>
          <Route path="/usuarios/editar/:id">
            <EditUser />
          </Route>
          <Route exact path="/categorias">
            <Categories />
          </Route>
          <Route exact path="/categorias/criar">
            <CreateCategory />
          </Route>
          <Route path="/categorias/editar/:id">
            <EditCategory />
          </Route>
          <Route exact path="/importar-e-exportar-clientes">
            <ImportExportCustomersPage />
          </Route>
          <Route path="*">
            <Redirect to="/clientes" />
          </Route>
        </Switch>
      </Router>
    );
  }

  return (
    <Router>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="*">
          <Redirect to="/login" />
        </Route>
      </Switch>
    </Router>
  );
}
