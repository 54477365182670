import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { getCategories } from '../../api/categories/getCategories';
import { createCustomer } from '../../api/customers/createCustomer';
import { getCustomerById } from '../../api/customers/getCustomerById';
import { updateCustomer } from '../../api/customers/updateCustomer';
import uf from '../../utils/uf';
import Alert from '../Alert/Alert';
import Loading from '../Loading/Loading';
import CepInput from '../MaskedInput/CepInput';
import CnpjInput from '../MaskedInput/CnpjInput';
import CpfInput from '../MaskedInput/CpfInput';
import MobilePhoneInput from '../MaskedInput/MobilePhoneInput';
import PhoneInput from '../MaskedInput/PhoneInput';

const schema = yup.object().shape({
  name: yup.string().required('Este campo é obrigatório')
});

function CustomerForm({ id }) {
  const history = useHistory();
  const { control, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(schema)
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          const res = await getCustomerById({ id });

          setData(res);
          setLoadingData(false);
        } catch (error) {
          setLoadingData(false);
          Alert.fire(error.message, '', 'error');
        }
      })();
    } else {
      setLoadingData(false);
    }
  }, [id]);

  useEffect(() => {
    (async () => {
      try {
        const res = await getCategories({ page: 1, limit: 9999 });
        setCategories(res.data);
      } catch (error) {
        //
      }
    })();
  }, []);

  const handleChangeZipCode = async (event) => {
    const { value } = event.target;
    if (typeof value !== 'string') {
      return;
    }
    const numberPattern = /\d+/g;
    const zipcode = value.match(numberPattern)?.join('');
    if (navigator.onLine && zipcode && zipcode.length === 8) {
      try {
        const res = await fetch(`https://viacep.com.br/ws/${zipcode}/json/`);
        const resData = await res.json();

        if (resData?.erro) {
          setValue('neighborhood', '');
          setValue('street_address', '');
          setValue('street_address_line_1', '');
          setValue('city', '');
          setValue('state', '');
          return;
        }

        setValue('neighborhood', resData?.bairro);
        setValue('street_address', resData?.logradouro);
        setValue('street_address_line_1', resData?.complemento);
        setValue('city', resData?.localidade);
        setValue('state', resData?.uf);
      } catch (error) {
        //
      }
    }
  };

  const onSubmit = async (formData) => {
    try {
      setLoading(true);

      if (id) {
        await updateCustomer({ id, ...formData });
        history.push('/clientes');
      } else {
        await createCustomer(formData);
        history.push('/clientes');
      }
    } catch (error) {
      setLoading(false);
      Alert.fire('', error.message, 'error');
    }
  };

  if (loadingData) {
    return <Loading />;
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Typography
        component="span"
        style={{ fontWeight: 'bold', color: '#999' }}
      >
        Dados Pessoais
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} md={8}>
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextField
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={!!errors.name?.message}
                helperText={errors.name?.message}
                margin="dense"
                required
                fullWidth
                id="name"
                label="Nome"
                name="name"
              />
            )}
            name="name"
            defaultValue={data?.name || ''}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <KeyboardDatePicker
                format="dd/MM/yyyy"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={!!errors.birthdate?.message}
                helperText={errors.birthdate?.message}
                margin="dense"
                fullWidth
                id="birthdate"
                label="Data de Aniversário"
                name="birthdate"
                cancelLabel="Fechar"
              />
            )}
            name="birthdate"
            defaultValue={data?.birthdate ? parseISO(data?.birthdate) : null}
          />
        </Grid>
      </Grid>
      <br />
      <Typography
        component="span"
        style={{ fontWeight: 'bold', color: '#999' }}
      >
        Endereço
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextField
                onBlur={onBlur}
                onChange={(event) => {
                  handleChangeZipCode(event);
                  onChange(event);
                }}
                value={value}
                error={!!errors.zipcode?.message}
                helperText={errors.zipcode?.message}
                margin="dense"
                fullWidth
                id="zipcode"
                label="CEP"
                name="zipcode"
                InputProps={{
                  inputComponent: CepInput
                }}
              />
            )}
            name="zipcode"
            defaultValue={data?.zipcode || ''}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextField
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={!!errors.city?.message}
                helperText={errors.city?.message}
                margin="dense"
                fullWidth
                id="city"
                label="Cidade"
                name="city"
              />
            )}
            name="city"
            defaultValue={data?.city || ''}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextField
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={!!errors.state?.message}
                helperText={errors.state?.message}
                margin="dense"
                fullWidth
                id="state"
                label="UF"
                name="state"
                select
              >
                {uf.map((option) => (
                  <MenuItem key={option.sigla} value={option.sigla}>
                    {option.sigla}
                    {' - '}
                    {option.nome}
                  </MenuItem>
                ))}
              </TextField>
            )}
            name="state"
            defaultValue={data?.state || ''}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextField
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={!!errors.street_address?.message}
                helperText={errors.street_address?.message}
                margin="dense"
                fullWidth
                id="street_address"
                label="Logradouro"
                name="street_address"
              />
            )}
            name="street_address"
            defaultValue={data?.street_address || ''}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextField
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={!!errors.street_number?.message}
                helperText={errors.street_number?.message}
                margin="dense"
                fullWidth
                id="street_number"
                label="Número"
                name="street_number"
              />
            )}
            name="street_number"
            defaultValue={data?.street_number || ''}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextField
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={!!errors.neighborhood?.message}
                helperText={errors.neighborhood?.message}
                margin="dense"
                fullWidth
                id="neighborhood"
                label="Bairro"
                name="neighborhood"
              />
            )}
            name="neighborhood"
            defaultValue={data?.neighborhood || ''}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextField
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={!!errors.street_address_line_1?.message}
                helperText={errors.street_address_line_1?.message}
                margin="dense"
                fullWidth
                id="street_address_line_1"
                label="Complemento"
                name="street_address_line_1"
              />
            )}
            name="street_address_line_1"
            defaultValue={data?.street_address_line_1 || ''}
          />
        </Grid>
      </Grid>
      <br />
      <Typography
        component="span"
        style={{ fontWeight: 'bold', color: '#999' }}
      >
        Contato
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextField
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={!!errors.mobile_phone?.message}
                helperText={errors.mobile_phone?.message}
                margin="dense"
                fullWidth
                id="mobile_phone"
                label="Celular"
                name="mobile_phone"
                InputProps={{
                  inputComponent: MobilePhoneInput
                }}
              />
            )}
            name="mobile_phone"
            defaultValue={data?.mobile_phone || ''}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextField
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={!!errors.phone?.message}
                helperText={errors.phone?.message}
                margin="dense"
                fullWidth
                id="phone"
                label="Telefone Residencial"
                name="phone"
                InputProps={{
                  inputComponent: PhoneInput
                }}
              />
            )}
            name="phone"
            defaultValue={data?.phone || ''}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextField
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={!!errors.phone_business?.message}
                helperText={errors.phone_business?.message}
                margin="dense"
                fullWidth
                id="phone_business"
                label="Telefone Comercial"
                name="phone_business"
                InputProps={{
                  inputComponent: PhoneInput
                }}
              />
            )}
            name="phone_business"
            defaultValue={data?.phone_business || ''}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextField
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={!!errors.email?.message}
                helperText={errors.email?.message}
                margin="dense"
                fullWidth
                id="email"
                label="E-mail"
                name="email"
              />
            )}
            name="email"
            defaultValue={data?.email || ''}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextField
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={!!errors.website?.message}
                helperText={errors.website?.message}
                margin="dense"
                fullWidth
                id="website"
                label="Site"
                name="website"
              />
            )}
            name="website"
            defaultValue={data?.website || ''}
          />
        </Grid>
      </Grid>
      <br />
      <Typography
        component="span"
        style={{ fontWeight: 'bold', color: '#999' }}
      >
        Identificação
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextField
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={!!errors.cpf?.message}
                helperText={errors.cpf?.message}
                margin="dense"
                fullWidth
                id="cpf"
                label="CPF"
                name="cpf"
                InputProps={{
                  inputComponent: CpfInput
                }}
              />
            )}
            name="cpf"
            defaultValue={data?.cpf || ''}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextField
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={!!errors.rg?.message}
                helperText={errors.rg?.message}
                margin="dense"
                fullWidth
                id="rg"
                label="RG"
                name="rg"
              />
            )}
            name="rg"
            defaultValue={data?.rg || ''}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextField
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={!!errors.cnpj?.message}
                helperText={errors.cnpj?.message}
                margin="dense"
                fullWidth
                id="cnpj"
                label="CNPJ"
                name="cnpj"
                InputProps={{
                  inputComponent: CnpjInput
                }}
              />
            )}
            name="cnpj"
            defaultValue={data?.cnpj || ''}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextField
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={!!errors.inscricao_municipal?.message}
                helperText={errors.inscricao_municipal?.message}
                margin="dense"
                fullWidth
                id="inscricao_municipal"
                label="Inscrição Municipal"
                name="inscricao_municipal"
              />
            )}
            name="inscricao_municipal"
            defaultValue={data?.inscricao_municipal || ''}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextField
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={!!errors.inscricao_estadual?.message}
                helperText={errors.inscricao_estadual?.message}
                margin="dense"
                fullWidth
                id="inscricao_estadual"
                label="Inscrição Estadual"
                name="inscricao_estadual"
              />
            )}
            name="inscricao_estadual"
            defaultValue={data?.inscricao_estadual || ''}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextField
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={!!errors.category_id?.message}
                helperText={errors.category_id?.message}
                margin="dense"
                fullWidth
                id="category_id"
                label="Categoria"
                name="category_id"
                select
              >
                <MenuItem value={null}>Sem Categoria</MenuItem>
                {categories.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.description}
                  </MenuItem>
                ))}
              </TextField>
            )}
            name="category_id"
            defaultValue={data?.category_id || ''}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextField
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={!!errors.obs?.message}
                helperText={errors.obs?.message}
                margin="dense"
                fullWidth
                multiline
                rows={10}
                id="obs"
                label="Anotações e Observações"
                name="obs"
              />
            )}
            name="obs"
            defaultValue={data?.obs || ''}
          />
        </Grid>
      </Grid>
      <br />
      <br />
      <br />
      <Grid container>
        <Grid item xs={12} md={12}>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            disabled={loading}
            style={{ marginRight: 15 }}
          >
            {loading ? 'Salvando...' : 'Salvar'}
          </Button>
          <Button
            type="button"
            variant="text"
            color="default"
            disabled={loading}
            onClick={() => history.goBack()}
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

CustomerForm.defaultProps = {
  id: null
};

CustomerForm.propTypes = {
  id: PropTypes.string
};

export default CustomerForm;
