import { getToken } from '../getToken';
import axios from '../index';

export async function updateUser({ id, ...data }) {
  try {
    await axios.put(`${process.env.REACT_APP_API_BACKEND}/users/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': getToken()
      }
    });
  } catch (error) {
    if (error?.response?.data?.error) {
      throw new Error(error?.response?.data?.error);
    }
    throw error;
  }
}
