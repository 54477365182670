import axios from 'axios';

axios.interceptors.response.use(
  function responseSuccess(response) {
    return response;
  },
  function responseError(error) {
    if (
      error?.response?.status === 401 &&
      error?.request?.responseURL?.match('/api/auth/login') === null
    ) {
      window.location.href = '/';
      localStorage.clear();
    }

    return Promise.reject(error);
  }
);

export default axios;
