import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { createUser } from '../../api/users/createUser';
import { getUserById } from '../../api/users/getUserById';
import { updateUser } from '../../api/users/updateUser';
import Alert from '../Alert/Alert';
import Loading from '../Loading/Loading';

const schema = yup.object().shape({
  name: yup.string().required('Este campo é obrigatório'),
  email: yup.string().required('Este campo é obrigatório'),
  password: yup
    .string()
    // eslint-disable-next-line no-template-curly-in-string
    .min(6, 'Deve ter no mínimo ${min} caracteres')
    .required('Este campo é obrigatório'),
  passwordConfirmation: yup
    .string()
    .required('Este campo é obrigatório')
    .oneOf([yup.ref('password'), null], 'As senhas não coincidem')
});

const schemaUpdate = yup.object().shape({
  name: yup.string().required('Este campo é obrigatório'),
  email: yup.string().required('Este campo é obrigatório'),
  password: yup
    .string()
    .trim()
    .transform((value) => (value === '' ? undefined : value))
    // eslint-disable-next-line no-template-curly-in-string
    .min(6, 'Deve ter no mínimo ${min} caracteres'),
  passwordConfirmation: yup
    .string()
    .trim()
    .transform((value) => (value === '' ? undefined : value))
    .oneOf([yup.ref('password'), null], 'As senhas não coincidem')
});

function UserForm({ id }) {
  const history = useHistory();
  const { control, handleSubmit, errors } = useForm({
    resolver: yupResolver(id ? schemaUpdate : schema)
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          const res = await getUserById({ id });

          setData(res);
          setLoadingData(false);
        } catch (error) {
          setLoadingData(false);
          Alert.fire(error.message, '', 'error');
        }
      })();
    } else {
      setLoadingData(false);
    }
  }, [id]);

  const onSubmit = async (formData) => {
    try {
      setLoading(true);

      if (id) {
        await updateUser({ id, ...formData });
      } else {
        await createUser(formData);
      }
      history.push('/usuarios');
    } catch (error) {
      setLoading(false);
      Alert.fire({
        title: error.message,
        type: 'error'
      });
    }
  };

  if (loadingData) {
    return <Loading />;
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <IconButton
            onClick={() => history.goBack()}
            style={{ marginTop: -5 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            component="span"
            style={{ fontWeight: 'bold', marginLeft: 5 }}
          >
            {id ? 'Editar Usuário' : 'Adicionar Novo Usuário'}
          </Typography>
        </Grid>
      </Grid>
      <Controller
        control={control}
        render={({ onChange, onBlur, value }) => (
          <TextField
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            error={!!errors.name?.message}
            helperText={errors.name?.message}
            margin="normal"
            required
            fullWidth
            id="name"
            label="Nome"
            name="name"
          />
        )}
        name="name"
        defaultValue={data?.name || ''}
      />
      <Controller
        control={control}
        render={({ onChange, onBlur, value }) => (
          <TextField
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            error={!!errors.email?.message}
            helperText={errors.email?.message}
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            type="email"
          />
        )}
        name="email"
        defaultValue={data?.email || ''}
      />
      <Controller
        control={control}
        render={({ onChange, onBlur, value }) => (
          <TextField
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            error={!!errors.password?.message}
            helperText={errors.password?.message}
            margin="normal"
            required={!id}
            fullWidth
            id="password"
            label="Senha"
            name="password"
            type="password"
          />
        )}
        name="password"
        defaultValue=""
      />
      <Controller
        control={control}
        render={({ onChange, onBlur, value }) => (
          <TextField
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            error={!!errors.passwordConfirmation?.message}
            helperText={errors.passwordConfirmation?.message}
            margin="normal"
            required={!id}
            fullWidth
            id="passwordConfirmation"
            label="Confirmar Senha"
            name="passwordConfirmation"
            type="password"
          />
        )}
        name="passwordConfirmation"
        defaultValue=""
      />
      <br />
      <br />
      <br />
      <Grid container>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            disabled={loading}
            style={{ marginRight: 15 }}
          >
            {loading ? 'Salvando...' : 'Salvar'}
          </Button>
          <Button
            type="button"
            variant="text"
            color="default"
            disabled={loading}
            onClick={() => history.goBack()}
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

UserForm.defaultProps = {
  id: null
};

UserForm.propTypes = {
  id: PropTypes.string
};

export default UserForm;
