import { getToken } from '../getToken';
import axios from '../index';

export async function getUsers(filters) {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_BACKEND}/users`, {
      params: {
        ...filters
      },
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': getToken()
      }
    });

    return res.data;
  } catch (error) {
    if (error?.response?.data?.error) {
      throw new Error(error?.response?.data?.error);
    }
    throw error;
  }
}
