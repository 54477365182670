import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { login } from '../api/login';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setUser(
      localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null
    );
    setLoading(false);
  }, []);

  async function signIn({ identifier, password }) {
    const res = await login({ identifier, password });

    localStorage.setItem('user', JSON.stringify(res));
    setUser(res);
  }

  async function signOut() {
    localStorage.clear();
    setUser(null);
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        signIn,
        signOut,
        loading
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider.');
  }

  return context;
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { AuthProvider, useAuth };
