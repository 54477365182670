/* eslint-disable react/no-array-index-key */
import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    padding: 10
  },
  row: {
    marginBottom: 10,
    flexDirection: 'row'
  },
  section: {
    marginRight: 15,
    minWidth: 100
  },
  titleSection: {
    backgroundColor: '#ddd',
    padding: 5,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  sectionSubtitle: {
    backgroundColor: '#eee',
    padding: 5,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  title: {
    fontSize: 10,
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  subtitle: {
    fontSize: 8,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: '#555'
  },
  date: {
    fontSize: 10,
    fontWeight: 'bold'
  },
  label: {
    fontSize: 8,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    marginBottom: 3,
    color: '#555'
  },
  input: {
    fontSize: 10
  }
});

// Create Document Component
const CustomerDataPdfDocument = ({ data }) => {
  const obs = data?.obs ? data.obs.split('\n') : [];
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.row}>
          <View style={styles.titleSection}>
            <Text style={styles.title}>Ficha do Cliente</Text>
            <Text style={styles.date}>
              {dayjs().format('DD/MM/YYYY HH:mm')}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.sectionSubtitle}>
            <Text style={styles.subtitle}>Dados Pessoais</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.section}>
            <Text style={styles.label}>Nome</Text>
            <Text style={styles.input}>{data?.name || '-'}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.label}>Data de Nascimento</Text>
            <Text style={styles.input}>
              {data?.birthdate
                ? dayjs(data?.birthdate).format('DD/MM/YYYY')
                : '-'}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.sectionSubtitle}>
            <Text style={styles.subtitle}>Endereço</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.section}>
            <Text style={styles.label}>CEP</Text>
            <Text style={styles.input}>{data?.zipcode || '-'}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.label}>Cidade</Text>
            <Text style={styles.input}>{data?.city || '-'}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.label}>UF</Text>
            <Text style={styles.input}>{data?.state || '-'}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.section}>
            <Text style={styles.label}>Logradouro</Text>
            <Text style={styles.input}>{data?.street_address || '-'}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.label}>Número</Text>
            <Text style={styles.input}>{data?.street_number || '-'}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.label}>Bairro</Text>
            <Text style={styles.input}>{data?.neighborhood || '-'}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.section}>
            <Text style={styles.label}>Complemento</Text>
            <Text style={styles.input}>
              {data?.street_address_line_1 || '-'}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.sectionSubtitle}>
            <Text style={styles.subtitle}>Contato</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.section}>
            <Text style={styles.label}>Celular</Text>
            <Text style={styles.input}>{data?.mobile_phone || '-'}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.label}>Telefone Residencial</Text>
            <Text style={styles.input}>{data?.phone || '-'}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.label}>Telefone Comercial</Text>
            <Text style={styles.input}>{data?.phone_business || '-'}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.section}>
            <Text style={styles.label}>E-mail</Text>
            <Text style={styles.input}>{data?.email || '-'}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.label}>Site</Text>
            <Text style={styles.input}>{data?.website || '-'}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.sectionSubtitle}>
            <Text style={styles.subtitle}>Identificação</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.section}>
            <Text style={styles.label}>CPF</Text>
            <Text style={styles.input}>{data?.cpf || '-'}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.label}>RG</Text>
            <Text style={styles.input}>{data?.rg || '-'}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.label}>CNPJ</Text>
            <Text style={styles.input}>{data?.cnpj || '-'}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.section}>
            <Text style={styles.label}>Inscrição Estadual</Text>
            <Text style={styles.input}>{data?.inscricao_estadual || '-'}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.label}>Inscrição Municipal</Text>
            <Text style={styles.input}>{data?.inscricao_municipal || '-'}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.sectionSubtitle}>
            <Text style={styles.subtitle}>Anotações e Observações</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.section}>
            {obs.map((text, index) => (
              <Text key={index} style={styles.input}>
                {text}
              </Text>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

CustomerDataPdfDocument.defaultProps = {
  data: null
};

CustomerDataPdfDocument.propTypes = {
  data: PropTypes.shape()
};

export default CustomerDataPdfDocument;
