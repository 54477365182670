import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { createCategory } from '../../api/categories/createCategory';
import { getCategoryById } from '../../api/categories/getCategoryById';
import { updateCategory } from '../../api/categories/updateCategory';
import Alert from '../Alert/Alert';
import Loading from '../Loading/Loading';

const schema = yup.object().shape({
  description: yup.string().required('Este campo é obrigatório')
});

function CategoryForm({ id }) {
  const history = useHistory();
  const { control, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          const res = await getCategoryById({ id });

          setData(res);
          setLoadingData(false);
        } catch (error) {
          setLoadingData(false);
          Alert.fire(error.message, '', 'error');
        }
      })();
    } else {
      setLoadingData(false);
    }
  }, [id]);

  const onSubmit = async (formData) => {
    try {
      setLoading(true);

      if (id) {
        await updateCategory({ id, ...formData });
      } else {
        await createCategory(formData);
      }
      history.push('/categorias');
    } catch (error) {
      setLoading(false);
      Alert.fire({
        title: error.message,
        type: 'error'
      });
    }
  };

  if (loadingData) {
    return <Loading />;
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <IconButton
            onClick={() => history.goBack()}
            style={{ marginTop: -5 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            component="span"
            style={{ fontWeight: 'bold', marginLeft: 5 }}
          >
            {id ? 'Editar Categoria' : 'Adicionar Nova Categoria'}
          </Typography>
        </Grid>
      </Grid>
      <Controller
        control={control}
        render={({ onChange, onBlur, value }) => (
          <TextField
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            error={!!errors.description?.message}
            helperText={errors.description?.message}
            margin="normal"
            required
            fullWidth
            id="description"
            label="Nome"
            name="description"
          />
        )}
        name="description"
        defaultValue={data?.description || ''}
      />
      <br />
      <br />
      <br />
      <Grid container>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            disabled={loading}
            style={{ marginRight: 15 }}
          >
            {loading ? 'Salvando...' : 'Salvar'}
          </Button>
          <Button
            type="button"
            variant="text"
            color="default"
            disabled={loading}
            onClick={() => history.goBack()}
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

CategoryForm.defaultProps = {
  id: null
};

CategoryForm.propTypes = {
  id: PropTypes.string
};

export default CategoryForm;
