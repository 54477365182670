import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Layout from '../../components/layout/Layout';
import UserForm from '../../components/users/Form';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  }
}));

function CreateUser() {
  const classes = useStyles();

  return (
    <Layout title="Usuários do sistema">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <UserForm />
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default CreateUser;
