import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ptBrLocale from 'date-fns/locale/pt-BR';
import React from 'react';
import Routes from './Routes';
import { AuthProvider } from './contexts/auth';
import './styles/global.css';
import theme from './theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default App;
