import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useParams } from 'react-router-dom';
import CustomerData from '../../components/customers/CustomerData';
import Layout from '../../components/layout/Layout';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  }
}));

function CustomerDataPage() {
  const classes = useStyles();
  const { id } = useParams();

  return (
    <Layout title="Clientes">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <CustomerData id={id} />
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default CustomerDataPage;
