import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import axios from 'axios';
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { getToken } from '../../api/getToken';
import Alert from '../Alert/Alert';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2)
    },
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  headerButtons: {
    [theme.breakpoints.down('md')]: {
      marginBottom: 15,
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      marginBottom: 0,
      marginRight: 15
    }
  }
}));

function ImportExportCustomers() {
  const classes = useStyles();
  const history = useHistory();
  const importInputRef = useRef(null);

  const handleClickImport = () => {
    importInputRef.current.click();
  };

  const handleClickExport = async () => {
    await axios.post(
      `/.netlify/functions/export-background`,
      {
        accessToken: getToken()
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': getToken()
        }
      }
    );

    Alert.fire({
      html: 'A exportação está sendo processada.<br/> Você receberá um email quando estiver concluída.',
      icon: 'success'
    });
  };

  const handleImportFileChange = (event) => {
    const file = event.target.files && event.target.files[0];
    if (!file) {
      return;
    }

    importInputRef.current.value = null;

    // Encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = async () => {
      // Use a regex to remove data url part
      const base64String = reader.result
        .replace('data:', '')
        .replace(/^.+,/, '');

      await axios.post(
        `/.netlify/functions/import-background`,
        {
          accessToken: getToken(),
          fileBase64: base64String
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': getToken()
          }
        }
      );

      Alert.fire({
        html: 'A importação está sendo processada.<br/> Você receberá um email quando estiver concluída.',
        icon: 'success'
      });
    };
    reader.readAsDataURL(file);
  };

  return (
    <div>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <IconButton
            onClick={() => history.goBack()}
            style={{ marginTop: -5 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            component="span"
            style={{ fontWeight: 'bold', marginLeft: 5 }}
          >
            Importar/Exportar Clientes
          </Typography>
        </Grid>
      </Grid>
      <br />
      <Button
        variant="outlined"
        color="primary"
        size="small"
        startIcon={<CloudUploadIcon />}
        onClick={handleClickImport}
        className={classes.headerButtons}
      >
        Importar
      </Button>
      <input
        style={{ display: 'none' }}
        ref={importInputRef}
        type="file"
        onChange={handleImportFileChange}
      />
      <Button
        variant="outlined"
        color="primary"
        size="small"
        startIcon={<CloudDownloadIcon />}
        className={classes.headerButtons}
        onClick={handleClickExport}
      >
        Exportar
      </Button>
      <p>Formatos permitidos: Excel (xlsx)</p>
      <p>
        Obs.: Antes de importar, você pode criar um único cadastro de exemplo e
        exportar a planilha.
        <br />
        Esta planilha será a mesma utilizada para importar dentro do sistema.
      </p>
      <br />
    </div>
  );
}

export default ImportExportCustomers;
