import { useMediaQuery } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SettingsIcon from '@material-ui/icons/Settings';
import Pagination from '@material-ui/lab/Pagination';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { deleteCategory } from '../../api/categories/deleteCategory';
import Alert from '../Alert/Alert';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3)
  }
}));

function CategoriesTable({ rows, onDelete, totalPages, page, onChangePage }) {
  const theme = useTheme();
  const mediaQueryMatchesLargeScreens = useMediaQuery(
    theme.breakpoints.up('lg')
  );
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [categorySelected, setCategorySelected] = useState(null);

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setCategorySelected(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCategorySelected(null);
  };

  const handleEdit = () => {
    history.push(`/categorias/editar/${categorySelected.id}`);
  };

  const handleDelete = async () => {
    setAnchorEl(null);
    Alert.fire({
      title: `Deseja realmente excluir a categoria ${categorySelected.description}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then(async (result) => {
      if (result.value) {
        try {
          await deleteCategory({ id: categorySelected?.id });
          if (onDelete) {
            onDelete();
          }
        } catch (error) {
          Alert.fire(error.message);
        }
      } else if (result.dismiss === Alert.DismissReason.cancel) {
        setCategorySelected(null);
      }
    });
  };

  const renderTableActions = (row, align = 'right') => {
    return (
      <TableCell align={align}>
        <IconButton
          aria-controls="settings-menu"
          aria-haspopup="true"
          onClick={(event) => handleClick(event, row)}
          size="small"
        >
          <SettingsIcon />
        </IconButton>
      </TableCell>
    );
  };

  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            {!mediaQueryMatchesLargeScreens ? (
              <TableCell align="left" width={40}>
                Ações
              </TableCell>
            ) : null}
            <TableCell>Nome</TableCell>
            {mediaQueryMatchesLargeScreens ? (
              <TableCell align="right" width={50}>
                Ações
              </TableCell>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              {!mediaQueryMatchesLargeScreens
                ? renderTableActions(row, 'left')
                : null}
              <TableCell>{row.description}</TableCell>
              {mediaQueryMatchesLargeScreens ? renderTableActions(row) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Menu
        id="settings-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEdit}>
          <EditIcon fontSize="small" />
          <span style={{ marginLeft: 8 }}>Alterar</span>
        </MenuItem>
        <MenuItem onClick={handleDelete} style={{ color: 'red' }}>
          <DeleteIcon fontSize="small" color="inherit" />
          <span style={{ marginLeft: 8 }}>Excluir</span>
        </MenuItem>
      </Menu>
      <div className={classes.seeMore}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={onChangePage}
          variant="outlined"
          color="primary"
          size="small"
        />
      </div>
    </>
  );
}

CategoriesTable.defaultProps = {
  onDelete: null,
  totalPages: undefined
};

CategoriesTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string
    })
  ).isRequired,
  onDelete: PropTypes.func,
  totalPages: PropTypes.number,
  page: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired
};

export default CategoriesTable;
