import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import CategoryIcon from '@material-ui/icons/Category';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import PersonIcon from '@material-ui/icons/Person';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import React from 'react';
import { useHistory } from 'react-router-dom';

const MenuLeft = () => {
  const history = useHistory();

  return (
    <div>
      <ListItem button onClick={() => history.push('/clientes')}>
        <Tooltip arrow title="Clientes" placement="right">
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Clientes" />
      </ListItem>
      <ListItem button onClick={() => history.push('/categorias')}>
        <Tooltip arrow title="Categorias" placement="right">
          <ListItemIcon>
            <CategoryIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Categorias" />
      </ListItem>
      <ListItem button onClick={() => history.push('/usuarios')}>
        <Tooltip arrow title="Usuários" placement="right">
          <ListItemIcon>
            <SupervisorAccountIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Usuários" />
      </ListItem>
      <ListItem
        button
        onClick={() => history.push('/importar-e-exportar-clientes')}
      >
        <Tooltip arrow title="Importar/Exportar Clientes" placement="right">
          <ListItemIcon>
            <ImportExportIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Importar/Exportar" />
      </ListItem>
    </div>
  );
};

export default MenuLeft;
