import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import logo from '../assets/ge.svg';
import Alert from '../components/Alert/Alert';
import { useAuth } from '../contexts/auth';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logo: {
    width: 192
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const schema = yup.object().shape({
  identifier: yup.string().required('Este campo é obrigatório'),
  password: yup.string().required('Este campo é obrigatório')
});

function Login() {
  const classes = useStyles();
  const { control, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  });
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (formData) => {
    try {
      setLoading(true);
      await signIn({ ...formData });
    } catch (error) {
      setLoading(false);
      Alert.fire(error.message);
    }
  };

  return (
    <Container component="main" maxWidth="xs" style={{ background: '#fff' }}>
      <div className={classes.paper}>
        <img src={logo} alt="Gestão Eficiente" className={classes.logo} />
        <Typography component="h3" variant="h6">
          CADASTRO DE CLIENTES
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextField
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={!!errors.identifier?.message}
                helperText={errors.identifier?.message}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="identifier"
                label="Usuário"
                name="identifier"
                autoComplete="identifier"
                autoFocus
              />
            )}
            name="identifier"
            defaultValue=""
          />
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextField
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={!!errors.password?.message}
                helperText={errors.password?.message}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Senha"
                type="password"
                id="password"
                name="password"
                autoComplete="current-password"
              />
            )}
            name="password"
            defaultValue=""
          />
          <Button
            type="submit"
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            disabled={loading}
            className={classes.submit}
          >
            {loading ? 'Entrando...' : 'Entrar'}
          </Button>
        </form>
      </div>
    </Container>
  );
}

export default Login;
